/**
 * Created by umbert on 29/04/2016.
 */

function addDiamondToCart(diamond_id, notif, rel_page) {
    var notification = typeof notif !== 'undefined' ?  notif : true;
    var reload_page = typeof rel_page !== 'undefined' ?  rel_page : false;

    $.ajax({
        url: laroute.route('public.cart.addItem', { diamond_id : diamond_id }),
        type: 'POST',
        success: function(response) {
            if(notification) {
                updateCartCount();
                notify(response.message, response.diamond_id, response.type);
            }

            if(reload_page) {
                location.reload();
            }
        }
    });
}

// Function to handle multiple simultaneous additions to the compare list at the same time (some duplicate code, could do with refactor)
function addDiamondsToCart(notif, rel_page) {
    var notification = typeof notif !== 'undefined' ?  notif : true;
    var reload_page = typeof rel_page !== 'undefined' ?  rel_page : false;

    $.ajaxSetup({
        header: $('meta[name="_token"]').attr('content')
    });

    var sendData = getChecked();
    var dJS = JSON.stringify(sendData);
    var user_id = $('#user_id').attr('data-id') ;

    $.ajax({
        url: '/cart/adds',
        type: 'POST',
        data: {list: dJS, user_id: user_id},
        dataType: 'json',
        success: function(response) {
            if(notification) {
                updateCartCount();
                new PNotify({
                    text: 'Shopping cart updated.',
                    type: 'success',
                    styling: 'bootstrap3',
                    delay: 4000
                });
            }

            if(reload_page) {
                location.reload();
            }
        }
    });
}

function getChecked() {
    var checkedValues = $('#compare-table :checked').map(function() {
        return this.value;
    }).get();
    return checkedValues ;
}

function removeDiamondFromCart(diamond_id,  notif, rel_page) {
    var notification = typeof notif !== 'undefined' ?  notif : true;
    var reload_page = typeof rel_page !== 'undefined' ?  rel_page : false;

    $.ajax({
        url: laroute.route('public.cart.removeItem', { diamond_id : diamond_id }),
        type: 'DELETE',
        success: function(response) {
            if(notification) {
                updateCartCount();
                notify(response.message, response.diamond_id, response.type);
            }

            if(reload_page) {
                location.reload();
            }
        }
    });
}

function emptyCart(notif, rel_page){
    var notification = typeof notif !== 'undefined' ?  notif : true;
    var reload_page = typeof rel_page !== 'undefined' ?  rel_page : false;

    $.ajax({
        url: laroute.route('public.cart.destroy'),
        type: 'DELETE',
        success: function() {
            if(notification) {
                updateCartCount();
                notify(response.message, response.diamond_id, response.type);
            }

            if(reload_page) {
                location.reload();
            }
        }
    });
}

function notify(message, item_id, type) {
    new PNotify({
        text: message + item_id,
        type: type,
        styling: 'bootstrap3',
        delay: 4000
    });
}

function updateCartCount(){
    $.ajax({
        url: laroute.route('public.cart.count'),
        type: 'GET',
        success: function(success) {
            $('#cart-count-badge').html(success);
        }
    });
}

//Functions below support 'Compare Stones' feature

function addFromCompare() {
    var checkedValues = $('#compare-selects input:checked').map(function() {
        return this.value;
    }).get();
    for (i = 0 ; i < checkedValues.length ; i++) {
        addDiamondToCart(checkedValues[i],true,false) ;
    }
}


//TODO BD :: Fade out diamond column not currently working...
function removeFromCompare() {
    var allDiamonds = $('#compare-selects input').map(function() {
        return this.value;
    }).get();
    var checkedValues = $('#compare-selects input:checked').map(function() {
        return this.value;
    }).get();
    $count = 0 ;
    for (i = 0 ; i < checkedValues.length ; i++) {
        $count =+ removeDiamondFromCompare(checkedValues[i],true,false) ;
        $("[data-id='"+checkedValues[i]+"']").fadeOut() ;
        $("[data-id='"+checkedValues[i]+"']").delay(2000).remove() ;
    }

    if (allDiamonds.length == checkedValues.length) {
        $('#compare-table').hide() ;
        $('#empty-msg').fadeIn() ;
    }
}

function removeDiamondFromCompare(diamond_id, notif, rel_page) {
    var notification = typeof notif !== 'undefined' ?  notif : true;
    var reload_page = typeof rel_page !== 'undefined' ?  rel_page : false;

    var user_id = $('#user_id').attr('data-id') ;

    $.ajax({
        //url: laroute.route('public.cart.removeItemCompare', { diamond_id : diamond_id }),
        url: 'compare/remove',
        type: 'DELETE',
        data: { diamond_id : diamond_id, user_id : user_id },
        dataType: 'json',
        async: false,
        success: function(response) {
            //console.log(response) ;
            return 1 ;
            /*if(notification) {
                updateCartCount();
                notify(response.message, response.diamond_id, response.type);
            }*/

            /*if(reload_page) {
                location.reload();
            }*/
        }
    });
    return 0 ;
}

function addToShortlist() {
    $('.sl-status').remove() ;
    $('.shortlist-btn').after("<span class='sl-status'><img src='img/loading.svg'/></span>") ;

    $.ajaxSetup({
        header:$('meta[name="_token"]').attr('content')
    });

    var user_id = $('#user_id').attr('data-id') ;
    var diamond_id = $('.container h1')[0].innerText ;
    var dJS = JSON.stringify(diamond_id.substring(6)) ;

    $.ajax({

        type: 'POST',
        url:'/compare/save',
        data: { list : dJS, user_id : user_id },
        dataType: 'json',
        success: function(data){
            $('.sl-status').remove() ;
            $('.shortlist-btn').after("<span style='color: #0093C3' class='sl-status glyphicon glyphicon-ok u-pad-left-'></span>") ;
            $('.sl-status').delay(3000).fadeOut() ;
            return 1 ;
        },
        error: function(data){
            $('.sl-status').remove() ;
            $('.shortlist-btn').after("<span style='color:red' class='sl-status glyphicon glyphicon-exclamation-sign u-pad-left-'></span>") ;
            $('.sl-status').delay(3000).fadeOut() ;
        }
    });
    $('.sl-status').remove() ;
    $('.shortlist-btn').after("<span style='color: #0093C3' class='sl-status glyphicon glyphicon-ok u-pad-left-'></span>") ;
    $('.sl-status').delay(3000).fadeOut() ;

    notify('Diamond added to comparison: ', diamond_id, 'success');

}
